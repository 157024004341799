export interface GalleryProp {
  image: string;
  name: string;
  year: string;
  description: string;
  size: string;
  sellStatus?: string;
}

export const GalleryItems: GalleryProp[] = [
  {
    image: "images/Geisha.jpg",
    name: "Geisha",
    year: "2022",
    description: "Acrylic and mixed media, on streched canvas",
    size: "38 cm by 76 cm by 4 cm",
  },
  {
    image: "images/LCouture.jpg",
    name: "London Couture",
    year: "2023",
    description: "Acrylic mixed media oil pastel, framed, on board",
    size: "45 cm cm by 60 cm by 4",
  },
  {
    image: "images/MMaze.jpg",
    name: "Mouse Maze",
    year: "2023",
    description: "Acrylic Inc markers oil pastel, framed, on paper",
    size: "41 cm by 50 cm",
  },
  {
    image: "images/SGarden.jpg",
    name: "Spring Garden",
    year: "2023",
    description: "Acrylic oil pastel Inc on paper, framed",
    size: "37 cm by 50 cm",
    sellStatus: "SOLD",
  },
  {
    image: "images/OFUniverse.jpg",
    name: "Our Fragile Universe",
    year: "2023",
    description: "Acrylic, oil pastel on carton, framed",
    size: "36 cm by 46 cm",
    sellStatus: "SOLD",
  },
  {
    image: "images/PECounty.jpg",
    name: "Prince Edward County",
    year: "2022",
    description: "Acrylic and oil pastel on carton, framed",
    size: "36 cm by 46 cm",
    sellStatus: "SOLD",
  },
  {
    image: "images/MPHoney.jpg",
    name: "My Precious Honey",
    year: "2022",
    description: "Acrylic and Oil Pastel on paper, framed",
    size: "37 cm by 51 cm",
    sellStatus: "SOLD",
  },
  {
    image: "images/EFairy.jpg",
    name: "Evening Fairy Tales",
    year: "2023",
    description: "Acrylic, oil pastel, and pen on carton, framed",
    size: "36 cm by 47 cm",
  },
  {
    image: "images/City.jpg",
    name: "My path from village to city",
    year: "2023",
    description: "Acrylic and pen on paper, framed",
    size: "51 cm by 40 cm",
  },
  {
    image: "images/Flowers.jpg",
    name: "First frost on flowers",
    year: "2022",
    description: "Acrylic, oil pastel, and pen on paper, framed",
    size: "26 cm by 51 cm",
  },
  {
    image: "images/Waterfalls.jpg",
    name: "Two arose from the waterfall",
    year: "2023",
    description: "Acrylic and pen on paper, framed",
    size: "35 cm by 58 cm by 4 cm",
  },
  {
    image: "images/CColumbus.jpg",
    name: "Columbus Christopher, Discipline in Freedom, on streched canvas",
    year: "2023",
    description: "Acrylic and mixed media",
    size: "38 cm by 76 cm",
  },
];

export const NewGalleryItems: GalleryProp[] = [
  {
    image: "images/TiffanyStyle.jpg",
    name: "Tiffany style",
    year: "2023",
    description: "mixed media, acrylic, framed",
    size: "50 cm by 50 cm",
  },
  {
    image: "images/AmalfiCoast.jpg",
    name: "Amalfi Coast",
    year: "2023",
    description: "Acrylic, on streched canvas",
    size: "30 cm by 30 cm",
  },
  {
    image: "images/12MilesLake.jpg",
    name: "12 Miles Lake",
    year: "2023",
    description: "acrylic, oil pastel, framed",
    size: "30 cm by 30 cm by 5 cm",
    sellStatus: "SOLD",
  },
  {
    image: "images/SeptemberWindowsill.jpg",
    name: "September on the Windowsill",
    year: "2023",
    description: "acrylic, oil pastel, on streched canvas",
    size: "30 cm by 30 cm",
  },
  {
    image: "images/CottonBlossom.jpg",
    name: "Cotton Blossom",
    year: "2023",
    description: "acrylic, framed",
    size: "21 cm by 21 cm by 4 cm",
  },
  {
    image: "images/2Lemons.jpg",
    name: "2 Lemons",
    year: "2023",
    description: "panel, acrylic, oil pastel",
    size: "13 cm by 13 cm by 5 cm",
  },
  {
    image: "images/GeorgianBaySeptember2023.jpg",
    name: "Georgian Bay September 2023",
    year: "2023",
    description: "Acrylic, oil pastel on paper, framed",
    size: "12 cm by 17 cm",
  },
  {
    image: "images/GeorgianBayOctober2023.jpg",
    name: "Georgian Bay October 2023",
    year: "2023",
    description: "oil, acrylic on paper, framed",
    size: "18 cm by 15 cm",
  },
  {
    image: "images/GeorgianBayNovember2023.jpg",
    name: "Georgian Bay November 2023",
    year: "2023",
    description: "oil pastel, acrylic on paper, framed",
    size: "18 cm by 24 cm",
  },
  {
    image: "images/Cranberry.jpg",
    name: "Cranberry",
    year: "2023",
    description: "oil pastel, acrylic on paper, framed",
    size: "9 cm by 17 cm",
  },
  {
    image: "images/Tulip.jpg",
    name: "Tulip",
    year: "2023",
    description: "acrylic on stretched canvas",
    size: "34 cm by 27 cm by 4 cm",
  },
  {
    image: "images/Untitled.jpg",
    name: "Untitled",
    year: "2023",
    description: "acrylic, oil pastel on panel",
    size: "13 cm 13 cm by 5 cm",
  },
  {
    image: "images/SpringAvatar.jpg",
    name: "Spring Avatar",
    year: "2024",
    description: "oil pastel, acrylic on paper, framed",
    size: "55 cm by 45 cm",
  },
  {
    image: "images/OwlsAreBirds.jpg",
    name: "Owls are birds",
    year: "2024",
    description: "oil pastel, acrylic on paper, framed",
    size: "28 cm by 42 cm",
  },
  {
    image: "images/ATownthatdoesntexist.jpg",
    name: "A town that doesn't exist",
    year: "2024",
    description: "oil pastel, markers, acrylic on canvas. framed",
    size: "25 cm by 30 cm",
    sellStatus: "SOLD",
  },
  {
    image: "images/FantasyinApril.jpg",
    name: "Fantasy in April",
    year: "2024",
    description: "oil pastel, acrylic on paper, framed",
    size: "49 cm by 63 cm",
  },
  {
    image: "images/SpringFantasyin2024.jpg",
    name: "Spring Fantasy in 2024",
    year: "2024",
    description: "oil pastel, markers, acrylic on paper, framed",
    size: "31 cm by 41 cm",
    sellStatus: "SOLD",
  },
  {
    image: "images/ATinyTown.jpg",
    name: "A Tiny Town",
    year: "2024",
    description: "markers, acrylic on canvas, framed",
    size: "10 cm by 14 cm",
    sellStatus: "SOLD",
  },
  {
    image: "images/CompositionWithVases.jpg",
    name: "Composition with vases",
    year: "2024",
    description: "Acrylic, oil pastel, ink on cartoon, framed",
    size: "39 cm by 55 cm",
  },
  {
    image: "images/July2024.jpg",
    name: "July 2024",
    year: "2024",
    description: "Acrylic, oil pastel on stretched canvas",
    size: "80 cm by 80 cm by 4 cm",
  },
  {
    image: "images/InTheWoods.jpg",
    name: "In The Woods",
    year: "2024",
    description: "Acrylic, oil pastel, ink on paper, framed",
    size: "43 cm by 56 cm",
  },
  {
    image: "images/CompositionInAugust2024.jpg",
    name: "Composition in August 2024",
    year: "2024",
    description: "Acrylic, oil pastel on stretched canvas",
    size: "51 cm by 41 cm by 4 cm",
  },
  {
    image: "images/FlowersAndUniverse.jpg",
    name: "Flowers and Universe",
    year: "2024",
    description: "Acrylic, oil pastel, ink on cartoon, framed",
    size: "56 cm by 31 cm",
  },
  {
    image: "images/KingOfMiseNutckracker2022.jpg",
    name: "King Of Mise. Nutckracker",
    year: "2022",
    description: "Acrylic, oil pastel on board",
    size: "15 cm by 20 cm",
    sellStatus: "SOLD",
  },
  {
    image: "images/Untitled2.jpg",
    name: "Untitled",
    year: "2024",
    description: "Acrylic, ink on canvas",
    size: "15 cm by 20 cm",
    sellStatus: "SOLD",
  },
  {
    image: "images/WinterFantasy.jpg",
    name: "Winter Fantasy",
    year: "2023",
    description: "Acrylic on paper, framed",
    size: "10 cm by 10 cm - 3",
    sellStatus: "SOLD",
  },
  {
    image: "images/Untitled3.jpg",
    name: "Untitled",
    year: "2023",
    description: "Acrylic on paper",
    size: "10 cm by 10 cm - 3",
    sellStatus: "2 SOLD OUT OF 4",
  },
];
