import { Footer } from "./Footer";

export const Commissions: React.FC = () => {
  return (
    <div id="page-wrapper">
      <article id="main">
        <header>
          <h2>Commissions</h2>
          <p style={{ backgroundImage: "url(images/1s.jpg)" }}>
            Create a custom window to your own world
          </p>
        </header>
        <section id="one" className="wrapper style1 special">
          <div className="inner">
            <header className="major">
              <h2>Create a personalized piece in Kobe's style</h2>
              <p>
                Contact me on social media, email me or through Etsy to discuss
                a custom piece
              </p>
              <ul className="icons">
                <li>
                  <a
                    href="https://www.etsy.com/ca/shop/KobeNArt?ref=seller-platform-mcnav"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fab fa-etsy"
                  >
                    <span className="label"></span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/people/Kobe-N-Art/pfbid0b6QZMMkBJQzZiPUaDatBM6Zo7aBkWGRNcf6kKZqB88uyhMHyDJuXnKA4wZVQ8jY2l/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="icon brands fa-facebook-f"
                  >
                    <span className="label">Facebook</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/kobe_n_art/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="icon brands fa-instagram"
                  >
                    <span className="label">Instagram</span>
                  </a>
                </li>
                <ul className="copyright">
                  <li>
                    <a href="mailto:art.n.kobe@gmail.com">
                      Email: art.n.kobe@gmail.com
                    </a>
                  </li>
                </ul>
              </ul>
            </header>
          </div>
        </section>
        <section className="wrapper style5">
          <div className="inner">
            <section>
              <div className="box alt">
                <div className="row ">
                  <div className="col-2"></div>
                  <div className="col-3">
                    <span className="image fit">
                      <img src="images/vlad.jpg" alt="" />
                    </span>
                  </div>
                  <div className="col-2"></div>
                  <div className="col-3">
                    <span className="image fit">
                      <img src="images/vladr.jpg" alt="" />
                    </span>
                  </div>
                  <div className="col-2"></div>
                </div>
                <hr />
                <div className="row ">
                  <div className="col-2"></div>
                  <div className="col-3">
                    <span className="image fit">
                      <img src="images/nastyar.jpg" alt="" />
                    </span>
                  </div>
                  <div className="col-2"></div>
                  <div className="col-3">
                    <span className="image fit">
                      <img src="images/nastya.jpg" alt="" />
                    </span>
                  </div>
                  <div className="col-2"></div>
                </div>
                <hr />
                <div className="row ">
                  <div className="col-2"></div>
                  <div className="col-3">
                    <span className="image fit">
                      <img src="images/girl.jpg" alt="" />
                    </span>
                  </div>
                  <div className="col-2"></div>
                  <div className="col-3">
                    <span className="image fit">
                      <img src="images/girlr.jpg" alt="" />
                    </span>
                  </div>
                  <div className="col-2"></div>
                </div>
                <hr />
                <div className="row ">
                  <div className="col-2"></div>
                  <div className="col-3">
                    <span className="image fit">
                      <img src="images/tbd.jpg" alt="" />
                    </span>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </article>

      <Footer />
    </div>
  );
};
